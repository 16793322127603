import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext.jsx";


const Add = ({ activeTab, setActiveTab, AllReferallInfo }) => {
  const [search, setSearch] = useState("");
  const [userinfo, setUserinfo] = useState([]);
  const [proxyfirstname, setProxyfirstname] = useState("");
  const [proxylastname, setPorxylastname] = useState("");
  const [proxyemail, setProxyemail] = useState("");
  const [proxycountry, setProxycountry] = useState("India");
  const [proxyphonenumber, setProxyphonenumber] = useState("");
  const [proxyprogram, setProxyprogram] = useState("MBA");
  const [error, setError] = useState("");
  const [referralerror, setReferralerror] = useState("");
  const [countries, setCountries] = useState([]);
  const [referrermessage, setReferrermessage] = useState("");
  const [errormessage, setErrormessage] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const { token, user } = useAuth();
  const utmSource = user?.source?.[0] || "defaultSource"; 
  const utmMedium = user?.medium?.[0] || "defaultMedium"; 
  const utmCampaign = user?.campaign?.[0] || "defaultCampaign"; 

  const referralUrl = `${process.env.REACT_APP_FRONTEND_URL}/?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;

  const emptySearch = () => {
    setSearch("")
    setUserinfo(0)
  }

  async function checkforEmail(event) {
    event.preventDefault();
    if (search !== "") {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/users/email/${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (resp.status === 200) {
          setUserinfo(resp.data.user.userInfo);
        }
      } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } else {
      console.log("entered the value");
    }
  }

  async function saveProxyDetail(event) {
    event.preventDefault();
    if (Object.keys(userinfo).length > 0) {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/referrals/proxy-lead`,
          {
            candidate_name: proxyfirstname + " " + proxylastname,
            candidate_email: proxyemail,
            candidate_phone: proxyphonenumber,
            candidate_program: proxyprogram,
            candidate_country: proxycountry,
            campaign: "NA",
            utm_source: "Proxy",
            medium: "NA",
            referrer_id: userinfo.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (resp.status === 201) {
          AllReferallInfo()
          setActiveTab(2);
          setProxyfirstname("");
          setPorxylastname("");
          setProxyemail("");
          setProxycountry("India");
          setProxyphonenumber("");
          setProxyprogram("MBA");
          setUserinfo([]);
          setSearch("");
        }
      } catch (error) {
        if (error.response.data.errors) {
          setReferralerror(error.response.data.errors[0].isEmail)
          setErrorPhone(error.response.data.errors[0].isPhoneNumber)
        } else {
          setReferralerror("")
          setErrorPhone("")
        }

        setErrormessage(error.response.data.message)
        setTimeout(() => {
          setErrormessage("")
          setReferralerror("")
          setErrorPhone("")
        }, 2000)
      }
    } else {
      setReferrermessage("referrer id not found")
      setTimeout(() => {
        setReferrermessage("")
      }, 2000)
    }
  }
  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      let countryNames = response.data.map(country => country.name.common);
      setCountries(countryNames);
    } catch (err) {
      console.log(err)
    }
  };


  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div>
      {activeTab === 9 && (
        <div>
          {user.user_type == 'SalesCounselor' && <div className="flex items-center m-4">
            <div className="text-start  font-bold text-xl">YOUR UTM URLS: </div>
            <div className="ml-3"> <a href={referralUrl} target="_blank" rel="noopener noreferrer">
              {referralUrl}
            </a></div>
          </div>}
          <div className="flex justify-between items-center">
            <div className="text-start p-4">
              <div>Search referer & add new referee</div>
              <div className="mt-2 text-sm text-gray-500">
                Add referee details on behalf of existing user by searching
                them.
              </div>
            </div>
            {/* <div className="px-4 flex items-center gap-x-8">
              <div>Add legacy referrals</div>
              <div className="text-red-500 rounded-full border-[1px] border-red-500 py-1 px-8 cursor-pointer">
                Add manually
              </div>
            </div> */}
          </div>
          <div className="flex ">
            <form className="w-[50%] bg-[#f3f4f6] rounded-lg flex flex-col" onSubmit={checkforEmail}>
              <div className="text-start px-4 pt-4">Search</div>
              <div className="text-start px-4 pt-1">
                <input
                  type="email"
                  placeholder="search your email"
                  className="rounded-lg w-[100%] p-2"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  required
                />
              </div>
              <div className="text-xs px-4 pt-2 text-[#828794] text-start">
                Search by entering either email address or 10-digit mobile
                number or student application number. If the user exists in the
                system then given user is considered as referer.
              </div>
              {error && <div className="text-start px-4 py-2">{error}</div>}
              <button
                className="text-red-500 rounded-full py-2 px-8 border-[1px] mx-4 my-4 border-red-500 w-max cursor-pointer"
                type="submit"
              >
                Check and update
              </button>
            </form>
            <div className="w-[50%]">
              <div className="text-start px-4 border-b-[1px] border-[#828794]">
                <div>Referer information</div>
                <div className="text-xs text-[#828794] pb-2">
                  Information about the person who is referring.
                </div>
              </div>
              <div className="bg-[#828794] text-start w-[95%] mt-2 m-auto">
                <div className="flex items-center justify-between px-4">
                  <div className="p-3">
                    {" "}
                    {Object.keys(userinfo).length > 0
                      ? userinfo.email
                      : "No Referer Selected"}
                  </div>
                  <div className="text-2xl cursor-pointer" onClick={emptySearch}  >{Object.keys(userinfo).length > 0
                    ? "x"
                    : ""}</div>
                </div>
                <div className="text-sm px-3 text-[#828794]">
                  Search for user and click on check & update to select found
                  user as referer.
                </div>
              </div>
              <div className="text-start p-5 border-b-[1px] border-[#828794]">
                <div>Referee information</div>
                <div className="text-xs text-[#828794]">
                  Information about the person who is getting referred.
                </div>
              </div>
              <div>
                <form action="" onSubmit={saveProxyDetail}>
                  <div className="text-start p-4">
                    <div>First name</div>
                    <div>
                      <input
                        type="text"
                        className="border-[1px] w-[95%] p-2 rounded-lg"
                        placeholder="Ex:ravi"
                        value={proxyfirstname}
                        onChange={(e) => setProxyfirstname(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-start px-4">
                    <div>Last name</div>
                    <div>
                      <input
                        type="text"
                        className="border-[1px] w-[95%] p-2 rounded-lg"
                        placeholder="Ex:Hiremath"
                        value={proxylastname}
                        onChange={(e) => setPorxylastname(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-start px-4 py-4">
                    <div>Email</div>
                    <div>
                      <input
                        type="email"
                        className="border-[1px] w-[95%] p-2 rounded-lg"
                        placeholder="Ex:email@example.com"
                        value={proxyemail}
                        onChange={(e) => setProxyemail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-start px-4 py-2">
                    <div>Where does referee currently reside?</div>
                    <div>
                      {/* <select
                        id="options"
                        name="options"
                        className="bg-white w-[100%] p-2 border-[1px]"
                        value={proxycountry}
                        onChange={(e) => setProxycontry(e.target.value)}
                        required
                      >
                        <option value=""></option>
                      </select> */}
                      <select
                        id="proxyCountry"
                        name="proxyCountry"
                        className="bg-white w-[100%] p-2 border-[1px]"
                        value={proxycountry} // Selected country
                        onChange={(e) => setProxycountry(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select country
                        </option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="text-xs text-[#828794]">
                      Select country where referer acquaintance currently
                      resides.
                    </div>
                  </div>
                  <div className="text-start px-4 py-2">
                    <div>Phone number</div>
                    <div>
                      <input
                        type="text" // Change type to text to allow for validation
                        className="border-[1px] w-[95%] p-2 rounded-lg"
                        placeholder="Ex: 8589238923"
                        value={proxyphonenumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Validate that the value is a number and has at most 10 digits
                          if (/^\d{0,10}$/.test(value)) {
                            setProxyphonenumber(value);
                          }
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-start px-4 py-2">
                    <div>Program</div>
                    <div>
                      <select
                        id="options"
                        name="options"
                        className="bg-white w-[100%] p-2 border-[1px]"
                        value={proxyprogram}
                        onChange={(e) => setProxyprogram(e.target.value)}
                        required
                      >
                        <option value="MBA">MBA</option>
                        <option value="MCA">MCA</option>
                        <option value="M.Com">M.Com</option>
                        <option value="MA">MA</option>
                        <option value="BBA">BBA</option>
                        <option value="BCA">BCA</option>
                        <option value="B.Com">B.Com</option>

                      </select>
                    </div>
                    <div className="text-xs text-[#828794]">
                      Select program you think referral might be interested in.
                      Select not set if you do not know.
                    </div>
                  </div>
                  {referralerror && <div className="text-red-500">{referralerror}</div>}
                  {referrermessage && <div className="mb-6 text-red-500"> {referrermessage}</div>}
                  {errormessage && <div className="mb-6 text-red-500">{errormessage}</div>}
                  {errorPhone && <div className="mb-6 text-red-500">{errorPhone}</div>}
                  <button
                    className="bg-red-500 p-2 rounded-xl cursor-pointer px-4 w-[95%] m-auto mb-8 mt-4"
                    type="submit"
                  >
                    Save & add
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default Add;
