
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../Context/AuthContext.jsx";
import axios from "axios";

const Myteam = ({ activeTab, toggletab, setToggletab }) => {
  const { token, user } = useAuth();
  const [teamdetail, setTeamdetail] = useState([]);
  const [searchEmail, setSearchEmail] = useState(""); // State to store search input
  const [teamfirstname, setTeamfirstname] = useState("");
  const [teamlastname, setTeamlastname] = useState("");
  const [teamemail, setTeamemail] = useState("");
  const [teamtype, setTeamtype] = useState("");
  const [error, setError] = useState("");
  const [source, setSource] = useState([]);
  const [campaign, setCampaing] = useState([]);
  const [medium, setMedium] = useState([])

  // Modified allTeamInfo to accept search query parameter
  const allTeamInfo = useCallback(async (searchEmail) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/team-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            email: searchEmail, // Add email query if provided
          },
        }
      );

      if (resp.status === 200) {
        const filteredTeamDetails = resp.data.filter(
          (item) => item.email !== user.email
        );
        setTeamdetail(filteredTeamDetails.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  // Fetch team info when component mounts or when searchEmail changes
  useEffect(() => {
    allTeamInfo(searchEmail); // Pass searchEmail as argument
  }, [allTeamInfo, searchEmail]);

  const handleSearchChange = (event) => {
    setSearchEmail(event.target.value); // Update search query state
  };

  const addNewmember = async (event) => {
    event.preventDefault();
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/add-team`,
        {
          first_name: teamfirstname + " " + teamlastname,
          email: teamemail,
          user_type: teamtype,
          source: [source],
          campaign: [campaign],
          medium: [medium]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resp.status === 201) {
        allTeamInfo(); // Refresh team info after adding a new member
        setToggletab(false);
        setTeamfirstname("");
        setTeamlastname("");
        setTeamemail("");
        setTeamtype("");
        setSource([]);
        setCampaing([])
        setMedium([])
      }
    } catch (error) {
      console.log(error);
      if (error.status === 400) {
        setError("eamil already exists")
        setTimeout(() => {
          setError("")
        }, 2000)
      }
    }
  };

  const changeStatus = async (id, currentStatus) => {
    try {
      // Toggle the status based on the current status
      const newStatus = currentStatus === "active" ? "deactive" : "active";

      const resp = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/deactivate`,
        {
          status: newStatus,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resp.status === 200) {
        allTeamInfo(); // Refresh the list after updating status
      }
    } catch (error) {
      console.log(error);
    }
  };


  const closetab = () => {
    setToggletab(false)
    setTeamfirstname("");
    setTeamlastname("");
    setTeamemail("");
    setTeamtype("");
    setSource([]);
    setCampaing([])
    setMedium([])
  }
  const handleArrayChange = (e, fieldSetter) => {
    fieldSetter(e.target.value);
  };

  return (
    <div>
      {activeTab === 7 && !toggletab && (
        <div>
          <div className="flex justify-between items-center">
            <div className="text-start p-4">
            </div>
            <div className="px-4 flex items-center gap-x-8 my-4">
              <input
                type="search"
                placeholder="Search team members"
                className="border-[1px] p-2 w-[20rem] outline-none"
                value={searchEmail} // Bind search input value to state
                onChange={handleSearchChange} // Update search state on input change
              />
              <div
                className="text-red-500 rounded-full border-[1px] border-red-500 py-1 px-8 cursor-pointer"
                onClick={() => setToggletab(true)}
              >
                Add new
              </div>
            </div>
          </div>
          <div className="overflow-x-auto pt-4">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Sr. No.</th>
                  <th className="px-4 py-2 border">Member </th>
                  <th className="px-4 py-2 border"> Type </th>
                  {/* <th className="px-4 py-2 border"> Is Super Admin </th> */}
                  <th className="px-4 py-2 border"> Added On </th>
                  <th className="px-4 py-2 border"> Source/Camapaign/Medium </th>
                  <th className="px-4 py-2 border"> Status </th>
                </tr>
              </thead>
              <tbody>
                {teamdetail.map((item, index) => (
                  <tr key={item.id}>
                    <td className="px-4 py-2 border">{index + 1}</td>
                    <td className="px-4 py-2 border">
                      {item.first_name} {item.last_name} <br />
                      {item.email}
                    </td>
                    <td className="px-4 py-2 border">{item.user_type}</td>
                    {/* <td className="px-4 py-2 border">NO</td> */}
                    <td className="px-4 py-2 border">
                      {new Date(item.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2 border">{item.source}/{item.campaign}/{item.medium}</td>
                    <td
                      className="px-4 py-2 border cursor-pointer"
                      onClick={() => changeStatus(item.id, item.status)}
                    >
                      <span className="font-bold">{item.status}</span>
                      <div className="text-xs">
                        by clicking you can make it  {item.status === "active" ? "deactive" : "active"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {toggletab && (
        <div>
          {toggletab && (
            <div>
              <div className="max-w-7xl m-auto">
                <div className="text-2xl pt-4 pb-2 font-medium">
                  Add new team member
                </div>
                <div className="text-sm text-[#868c98]">
                  Add new team member account.
                </div>
                <form className="py-4" onSubmit={addNewmember}>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">First Name</div>
                    <input
                      type="text"
                      className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                      placeholder="Ex:ravis"
                      value={teamfirstname}
                      onChange={(e) => setTeamfirstname(e.target.value)}
                      required
                    />
                  </div>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">Last Name</div>
                    <input
                      type="text"
                      className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                      placeholder="Ex:Hiremath"
                      value={teamlastname}
                      onChange={(e) => setTeamlastname(e.target.value)}
                      required
                    />
                  </div>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">Email</div>
                    <input
                      type="email"
                      className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                      placeholder="Ex:email@example.com"
                      value={teamemail}
                      onChange={(e) => setTeamemail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="text-red-500 font-semibold my-0 py-0">{error}</div>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">Account Type</div>
                    {user.user_type === 'Admin' &&
                      <select
                        name=""
                        id=""
                        className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                        value={teamtype}
                        onChange={(e) => setTeamtype(e.target.value)}
                        required
                      >
                        <option value="none">Select option</option>
                        <option value="SalesHead">Sales Head</option>
                        {/* <option value="DepartmentHead">Department Head</option> */}
                        <option value="SalesCounselor">Sales Counselor</option>
                      </select>}
                    {user.user_type === 'SalesHead' &&
                      <select
                        name=""
                        id=""
                        className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                        value={teamtype}
                        onChange={(e) => setTeamtype(e.target.value)}
                        required
                      >
                        <option value="none">Select option</option>
                        <option value="SalesCounselor">Sales Counselor</option>
                      </select>}
                  </div>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">Source</div>
                    <input
                      type="text"
                      className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                      placeholder="Source"
                      value={source}
                      onChange={(e) => handleArrayChange(e, setSource)}
                      required
                    />
                  </div>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">Campaign</div>
                    <input
                      type="text"
                      className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                      placeholder="Campaign"
                      value={campaign}
                      onChange={(e) => handleArrayChange(e, setCampaing)}
                      required
                    />
                  </div>
                  <div className="w-[30%] m-auto my-4">
                    <div className="text-start">Medium</div>
                    <input
                      type="text"
                      className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                      placeholder="Medium"
                      value={medium}
                      onChange={(e) => handleArrayChange(e, setMedium)}
                      required
                    />
                  </div>
                  <div>
                    <button
                      className="bg-red-500 w-[30%] m-auto py-2 rounded-full text-white font-bold text-lg cursor-pointer"
                      type="submit"
                    >
                      Save & add
                    </button>
                    <div
                      className="border border-red-500 w-[30%] m-auto py-2 rounded-full text-red-500 font-bold text-lg my-4 cursor-pointer"
                      onClick={closetab}
                    >
                      Cancel
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Myteam;
