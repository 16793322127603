import React, { useState, useEffect } from "react";
import logo from "../assets/vignan_online.png";
import hamberger from "../assets/hamberger.png";
import phoneGif from "../assets/Phone-Mobile-Gif.gif";
import plus from "../assets/output-onlinepngtools.png";
import student from "../assets/Top-referrer.jpeg";
import amazon from "../assets/Amazon_Voucher.png";
import Carousel from "../Component/Home/carousel.jsx";
import documentPen from "../assets/document_with_pen_Zxl4TSiYS.png";
import peopleConnecting from "../assets/people_connecting_gK6Ou2mH2.png";
import giftCard from "../assets/gift_card_ADP9BTEiz.png";
import redeemRewards from "../assets/Redeem_your_rewards.png";
import facebook from "../assets/Icon awesome-facebook.svg";
import instagram from "../assets/Icon awesome-instagram.svg";
import linkedin from "../assets/Icon awesome-linkedin.svg";
import youtube from "../assets/Icon awesome-youtube.svg";
import x from "../assets/logo.svg";
import { useLocation } from "react-router-dom";
import { useAuth } from "../Context/AuthContext.jsx";
import { useCampaign } from '../Context/CampaignContext.jsx';

const Home = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeInnerIndex, setActiveInnerIndex] = useState({});
  const { user } = useAuth();
  const location = useLocation();
  const { saveCampaignData } = useCampaign();

  const getQueryParams = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  const campaign = getQueryParams("utm_campaign") || "NA";
  const source = getQueryParams("utm_source") || "NA";
  const medium = getQueryParams("utm_medium") || "NA";

  useEffect(() => {
    saveCampaignData(source, campaign, medium);
  }, [source, campaign, medium, saveCampaignData]);

  const refferalBenifits = [
    {
      id: 1,
      image: documentPen,
      title: "Create account",
      content:
        "Quickly log in to your account using the VISA registered email credentials.",
      count: "01",
    },
    {
      id: 2,
      image: peopleConnecting,
      title: "Start referring",
      content:
        "Upon logging in, add the basic details of the individual/s you'd like to refer.",
      count: "02",
    },
    {
      id: 3,
      image: giftCard,
      title: "Get referral benefits",
      content:
        "Once your referral successfully enrolls and qualifies for our referral policy, the points will be added to your account.",
      count: "03",
    },
    {
      id: 4,
      image: redeemRewards,
      title: "Redeem vouchers",
      content:
        "Redeem your points against 100+ brand options on the rewards marketplace.",
      count: "04",
    },
  ];

  const refferalBenifitsMob = [
    {
      id: 1,
      image: documentPen,
      title: "Create account",
      content:
        "Quickly log in to your account using the VISA registered email credentials.",
      count: "01",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleInnerAccordion = (outerIndex, innerIndex) => {
    setActiveInnerIndex((prevState) => ({
      ...prevState,
      [outerIndex]: prevState[outerIndex] === innerIndex ? null : innerIndex,
    }));
  };

  const faqMcq = [
    {
      title: "Eligibility",
      Insidecontent: [
        {
          insideContentTitle:
            "What are the eligibility criteria to receive a Referral Bonus after referring a friend?",
          insideContentContent:
            "It could be either or both -<br> The referee has successfully enrolled in a program.<br > The referee has cleared the admission and verification process.",
        },
        {
          insideContentTitle:
            "Do I need to be a VIGNAN learner to refer my friends?",
          insideContentContent:
            "Yes. Currently, the referral program is only open for VIGNAN Online learners.",
        },
      ],
    },
    {
      title: "How to use",
      Insidecontent: [
        {
          insideContentTitle: "How can I refer my friends/connections?",
          insideContentContent:
            "If you've already enrolled with VIGNAN: <br /> Fill out the basic details of the individual/s you'd like to refer.<br /> Alternatively, share the referral invite link with them.<br /> If you haven't signed up with VIGNAN yet:<br /> Sign up first, then proceed with the steps mentioned above.",
        },
      ],
    },
    {
      title: "Payouts",
      Insidecontent: [
        {
          insideContentTitle:
            "What can I expect to receive the referral points?",
          insideContentContent:
            "Once the referee meets the required eligibility criteria, our team will add the points to your dashboard, which can be redeemed at our exclusive rewards marketplace.",
        },
        {
          insideContentTitle: "I have still not received my referral bonus",
          insideContentContent:
            "That could be because - <br /> The referee might not have completed the enrollment process. <br /> The admission has been rejected due to ineligibility.",
        },
      ],
    },
  ];

  const handleHamburgerClick = () => {
    setIsButtonVisible((prevState) => !prevState);
  };

  return (
    <>
      {/* header */}
      <div className="shadow-xl">
        <div className="max-w-7xl 2xl:max-w-[90rem] m-auto">
          <div className="flex justify-between items-center p-6  ">
            <a href="/">
              <div>
                <img src={logo} alt="logo" />
              </div>
            </a>
            {
              user === null && <a href="/login">
                <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block">
                  Login
                </div>
              </a>
            }
            {
              user?.id !== null && user?.user_type === "Student" && <a href="/student">
                <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block">
                  Student Dashboard
                </div>
              </a>
            }
            {
              user?.id !== null &&
              (user?.user_type === "Admin" ||
                user?.user_type === "SalesHead" ||
                user?.user_type === "SalesCounselor") && (
                <a href="/sales">
                  <div className="text-[15px] px-[1.3rem] py-[0.5rem] border-x border-y rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block">
                    Sales Dashboard
                  </div>
                </a>
              )
            }

            <div
              className="md:hidden w-[1.5rem]"
              onClick={handleHamburgerClick}
            >
              <img src={hamberger} alt="hamberger" />
            </div>
          </div>
          {isButtonVisible && (
            <a href="/login">
              <div className="text-[15px] px-[1.3rem] py-[0.5rem] border-x border-y  rounded-full cursor-pointer transition-all text-red-500 border-red-500 w-[20rem] m-auto">
                Login
              </div>
            </a>
          )}
        </div>
      </div>
      {/* header */}
      {/*banner  */}
      <div className="bg-[#efefef]">
        <div className="max-w-7xl 2xl:max-w-[90rem] m-auto">
          <div className="flex flex-col md:flex-row justify-between">
            {/*first */}
            <div className="text-start px-4 ">
              <div className="text-2xl lg:text-5xl text-[#004f6b] py-4 border-b-[4px] border-solid  border-[#ebebeb]">
                Now Unlock 100+ Vouchers at the Exclusive Rewards <br />{" "}
                Marketplace!
              </div>
              <div className="py-6 text-[#797e81] w-[20rem] md:w-[30rem]">
                Refer someone to Vignan Online Degree Programs and receive{" "}
                <strong>3,000 points</strong> [1 point = 1 INR] for each
                successful referral. Redeem them across a range of categories,
                including mobile recharge, OTT subscriptions, movie tickets,
                fashion, electronics, travel & more!
              </div>
              <div className="text-[#004f6b] pb-4">
                The more you refer, the more you earn.
              </div>
              {
                user === null && <a href="/login">
                  <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                    Refer Now
                  </div>
                </a>
              }
              {
                user?.id !== null && user?.user_type === "Student" && <a href="/student">
                  <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y w-max  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                    Add new referral
                  </div>
                </a>
              }
              {
                user?.id !== null &&
                (user?.user_type === "Admin" ||
                  user?.user_type === "SalesHead" ||
                  user?.user_type === "SalesCounselor") && <a href="/sales">
                  <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y w-max  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                    Sales dashboard
                  </div>
                </a>
              }
            </div>
            {/*first */}
            {/*Second */}
            <div>
              <div className="flex">
                <div className="self-end mb-8 w-[3rem]">
                  <img src={plus} alt="plus" />
                </div>
                <div className="ssm:w-[6rem] w-[10rem] lg:w-[28rem]">
                  <img src={phoneGif} alt="phoneGif" />
                </div>
              </div>
            </div>
            {/*Second */}
          </div>
          {/*refer of the month mobile  */}
          <div className="flex flex-col justify-center lg:hidden py-4">
            <div className="text-3xl text-[#00215c]">Referrer of the month</div>
            <div className="text-[#00215c] text-xl">
              Referred 3 friends and Received 3{" "}
            </div>
            <div className="text-[#00215c] text-xl">
              amazon vouchers worth 3000/-
            </div>
            <div className="w-[16rem] m-auto my-2">
              <img src={student} alt="studen" />
            </div>
            <div className="text-start text-[#212529] w-[20rem] m-auto my-4">
              Hi, I'm Vamsi, and I want to share my experience with Vignan
              Online. Their live classes were engaging. What stood out for me
              was their referral program. I referred three friends and got ₹3000
              Amazon vouchers for each referral. It made my experience even
              better and showed how much they value their users.
            </div>
            <div className="flex m-auto gap-x-10">
              <div className="font-bold text-black text-md">
                Polisetty Vamsikrishna <br />
                MCA (Data Science)
              </div>
              <div className="">
                <div>Rewards Redeemed</div>
                <img src={amazon} alt="amazon" className="w-[7rem]" />
              </div>
            </div>
            <div className="text-[#6a737e] text-md ssm:w-[15rem] w-[20rem] m-auto">
              Now, you have the opportunity to earn these rewards and many more.
            </div>
            {
              user === null && <a href="/login">
                <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                  Refer Now
                </div>
              </a>
            }
            {
              user?.id !== null && user?.user_type === "Student" && <a href="/student">
                <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y w-max  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                  Start Referring Now
                </div>
              </a>
            }
            {
              user?.id !== null &&
              (user?.user_type === "Admin" ||
                user?.user_type === "SalesHead" ||
                user?.user_type === "SalesCounselor") && <a href="/sales">
                <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y w-max  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                  Sales dashboard
                </div>
              </a>
            }
          </div>
          {/*refer of the month mobile  */}
          {/*refer of the month desktop */}
          <div className=" justify-between gap-x-10 my-4 hidden lg:flex">
            <div className="w-[40rem]">
              <img src={student} alt="student" />
            </div>
            <div className="text-start ">
              <div className="text-4xl">Referrer of the month</div>
              <div className="text-2xl py-4">
                Referred 3 friends and Received 3 amazon vouchers worth 3000/-
              </div>
              <div className="text-base text-[#212529]">
                Hi, I'm Vamsi, and I want to share my experience with Vignan
                Online. Their live classes were engaging. What stood out for me
                was their referral program. I referred three friends and got
                ₹3000 Amazon vouchers for each referral. It made my experience
                even better and showed how much they value their users.
              </div>
              <div className="flex gap-x-40 py-3">
                <div className="text-black font-bold">
                  Polisetty Vamsikrishna <br />
                  MCA (Data Science)
                </div>
                <div>
                  <div>Rewards Redeemed</div>
                  <img src={amazon} alt="amzon_voucher" className="w-[5rem]" />
                </div>
              </div>
              <div className="text-[#6a737e]">
                Now, you have the opportunity to earn these rewards and many
                more.
              </div>
              {
                user === null && <a href="/login">
                  <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                    Refer Now
                  </div>
                </a>
              }
              {
                user?.id !== null && user?.user_type === "Student" && <a href="/student">
                  <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y w-max  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                    Start Referring Now
                  </div>
                </a>
              }
              {
                user?.id !== null &&
                (user?.user_type === "Admin" ||
                  user?.user_type === "SalesHead" ||
                  user?.user_type === "SalesCounselor") && <a href="/sales">
                  <div className="text-[15px]  px-[1.3rem] py-[0.5rem] border-x border-y w-max  rounded-full cursor-pointer transition-all text-red-500 border-red-500 hidden md:block w-max">
                    Sales dashboard
                  </div>
                </a>
              }
            </div>
          </div>
          {/*refer of the month desktop  */}
        </div>
      </div>
      {/* banner */}
      {/* poplar vouchers */}
      <div className="max-w-7xl 2xl:max-w-[90rem] m-auto py-4">
        <Carousel />
      </div>
      {/* poplar vouchers */}
      {/* getting started */}
      <div className="max-w-7xl 2xl:max-w-[90rem] m-auto relative">
        <div className=" bg-[#004f6b] my-4 h-[7rem] lg:h-[10rem]"></div>
        <div className="absolute top-[30%]">
          <div className="hidden lg:flex lg:justify-between lg:gap-[4rem] xl:gap-x-[8rem] ">
            {refferalBenifits.map((item) => (
              <div key={item.id}>
                <div className="w-[10rem] bg-white rounded-full shadow-xl p-8">
                  <img src={item.image} alt={item.title} />
                </div>
                <div className="my-4 w-[13rem] relative">
                  <div className="bg-[#05739b] p-1 text-white text-start  font-bold rounded-lg ">
                    {item.title}
                  </div>
                  <div className="text-center bg-white shadow-lg rounded-lg h-[11rem] pt-2">
                    {item.content}
                  </div>
                  <div className="rounded-full bg-red-500 w-max p-6 text-white absolute bottom-[-12%] left-[30%] ">
                    {item.count}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute top-[15%] left-[25%] md:left-[40%]">
          <div className="flex justify-around lg:hidden">
            {refferalBenifitsMob.map((item) => (
              <div key={item.id} >
                <div className="w-[10rem] bg-white rounded-full shadow-xl p-8 m-auto">
                  <img src={item.image} alt={item.title} />
                </div>
                <div className="my-4 w-[13rem] relative">
                  <div className="bg-[#05739b] p-1 text-white text-center font-bold rounded-lg ">
                    {item.title}
                  </div>
                  <div className="text-center bg-white shadow-lg rounded-lg h-[11rem] pt-2">
                    {item.content}
                  </div>
                  <div className="rounded-full bg-red-500 w-max p-6 text-white absolute bottom-[-12%] left-[30%] ">
                    {item.count}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* getting started */}
      {/* faq*/}
      <div className="max-w-7xl 2xl:max-w-[90rem] m-auto mt-[22rem]">
        <div className="bg-red-500 w-[50%] m-auto rounded-lg text-start text-white h-[8rem] p-3 font-semibold text-2xl lg:hidden">
          Frequently <br /> Asked <br /> Questions
        </div>
        <div className="flex justify-center my-8">
          <div className="bg-red-500 w-[30%] rounded-lg text-start text-white h-[8rem] p-3 font-semibold text-2xl hidden lg:block">
            Frequently <br /> Asked <br /> Questions
          </div>
          <div className="w-[90%] lg:w-[70%]">
            {faqMcq.map((item, index) => (
              <div key={index} className="accordion-item">
                <div
                  className="accordion-title flex justify-between px-10 py-4 bg-white shadow-lg mb-2 cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <h2 className="font-medium text-[#05739b] ">{item.title}</h2>
                  <span className="">{activeIndex === index ? "-" : "+"}</span>
                </div>
                {activeIndex === index && (
                  <div className="accordion-content bg-white shadow-sm">
                    {item.Insidecontent.map((content, innerIndex) => (
                      <div
                        key={innerIndex}
                        className="inner-accordion-item shadow-sm"
                      >
                        <div
                          className="inner-accordion-title flex justify-between px-4 cursor-pointer my-4  "
                          onClick={() =>
                            toggleInnerAccordion(index, innerIndex)
                          }
                        >
                          <h4>{content.insideContentTitle}</h4>
                          <span className="text-start">
                            {activeInnerIndex[index] === innerIndex ? "-" : "+"}
                          </span>
                        </div>
                        {activeInnerIndex[index] === innerIndex && (
                          <div className="inner-accordion-content text-start pl-4" >
                            <p dangerouslySetInnerHTML={{ __html: content.insideContentContent }}></p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* faq*/}
      {/* footer*/}
      <div className="max-w-7xl 2xl:max-w-[90rem] m-auto my-4">
        <div className="flex bg-[#004f6b] py-5 justify-between px-4">
          <div className="text-xs text-white px-4 w-[13rem] lg:w-max">
            © Copyright VIGNAN Online (Deemed to be University). All Rights
            Reserved.
          </div>
          <div className="flex gap-x-5 pr-4">
            <a href="https://x.com/vignanonline" target="_blank" rel="noreferrer" >
              <div className="cursor-pointer">
                <img src={x} alt="twitter" className="" />
              </div>
            </a>
            <a href="https://www.facebook.com/vignanonline/" target="_blank" rel="noreferrer" >
              <div className="cursor-pointer">
                <img src={facebook} alt="facebook" />
              </div>
            </a>
            <a href="https://www.instagram.com/vignanonline/" target="_blank" rel="noreferrer" >
              <div className="cursor-pointer">
                <img src={instagram} alt="instagram" />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/vignan-online/" target="_blank" rel="noreferrer" >
              <div className="cursor-pointer">
                <img src={linkedin} alt="linkedin" />
              </div>
            </a>
            <a href="https://www.youtube.com/channel/UC9xOL6RM5DShuroZMecmEvg" target="_blank" rel="noreferrer" >
              <div className="cursor-pointer">
                <img src={youtube} alt="youtube" />
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* footer*/}
    </>
  );
};

export default Home;
