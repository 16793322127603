import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../Context/AuthContext.jsx";
import { useCampaign } from '../Context/CampaignContext.jsx';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { token } = useAuth();
  const { saveCampaignData } = useCampaign();

  const getQueryParams = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };
  
  const campaign = getQueryParams("utm_campaign") || "NA";
  const source = getQueryParams("utm_source") || "NA";
  const medium = getQueryParams("utm_medium") || "NA"

  saveCampaignData(source, campaign, medium);

  return token ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{
        from: location,
        utm_campaign: campaign,
        utm_source: source,
        utm_medium: medium
      }}
      replace
    />
  );
};

export default RequireAuth;
