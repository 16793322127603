
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../Context/AuthContext.jsx";

const DailyRedeemption = ({ activeTab }) => {
  const { token } = useAuth();
  const [pointrule, setPointrule] = useState(null); // Default to null
  const [showAll, setShowAll] = useState(false); // State to toggle visibility
  const [redeempoint, setRedeempoint] = useState("");
  const [message, setMessage] = useState("")

  const currentRules = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/pointrule/currentcap`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setPointrule(response.data.pointRule); // Ensure this matches the API response
      }
    } catch (error) {
      console.error("Error fetching current rules:", error);
    }
  }, [token]);

  useEffect(() => {
    if (activeTab === 10) {
      currentRules();
    }
  }, [activeTab, currentRules]);

  const toggleShowMore = () => {
    setShowAll((prev) => !prev); // Toggle the state
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/pointrule/updatecap`,
        {
          "dailyreedemPoints": parseInt(redeempoint)
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (response.status === 200) {
        setMessage(response.data.message)
        currentRules();
      }
      setTimeout(() => {
        setMessage("");
        setRedeempoint('')
      }, 2000)

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {activeTab === 10 && (
        <div>
          <div className="text-red-500 text-start m-4 font-bold text-lg">Note : The updated cap value will take effect starting at 12 AM</div>
          <div className="text-start mx-3 font-bold text-xl">last updated by:</div>
          <table
            border="1"
            cellPadding="10"
            cellSpacing="0"
            className="min-w-full bg-white border border-gray-300"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Cap Value</th>
                <th>Last Updated By</th>
              </tr>
            </thead>
            <tbody>
              {pointrule?.last_updated_by ? (
                (showAll
                  ? [...pointrule.last_updated_by].reverse() // Show all values if `showAll` is true
                  : [pointrule.last_updated_by.at(-1)] // Show only the last value if `showAll` is false
                ).map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.id || "N/A"}</td>
                    <td>{entry.email || "N/A"}</td>
                    <td>{entry.capvalue || "N/A"}</td>
                    <td>
                      {entry.timestamp
                        ? new Date(entry.timestamp).toLocaleString()
                        : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {pointrule?.last_updated_by && pointrule.last_updated_by.length > 1 && (
            <button
              onClick={toggleShowMore}
              style={{
                marginTop: "10px",
                padding: "8px 16px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {showAll ? "Show Less" : "Show More"}
            </button>
          )}

          <div className="text-start ml-4 my-4 text-xl font-bold">Currently Running Cap Value : {pointrule?.capvalue}</div>
          <div className="text-start ml-4 my-4 text-xl font-bold">Pending Cap Value : {pointrule?.pendingcapvalue}</div>
          <div className="text-start font-bold mx-4 py-4 text-xl">Update Daily Redeem Point Here:</div>
          <div className="text-start ml-4">
            <form action="" className="py-3" onSubmit={handleSubmit}>
              <input
                type="text"
                inputMode="numeric"
                placeholder="Enter Points"
                required
                className="border-[1px] border-black py-2 rounded outline-none pl-2"
                value={redeempoint}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) > 0)) {
                    setRedeempoint(value);
                  }
              }}
                min="1"
                step="1"
              />
              <button type="submit" className="bg-red-500 py-3 px-10 rounded ml-6">
                Add
              </button>
            </form>
            {message !== "" && <div className="text-green-500 font-bold text-xl">{message}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyRedeemption;
