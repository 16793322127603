import React, { useState, useEffect, useCallback } from "react";
import logo from "../assets/vignan_online.png";
import Home from "../Component/students/Home.jsx";
import Wallet from "../Component/students/Wallet.jsx";
// import Vouchers from "../Component/students/Vouchers.jsx";
import { useAuth } from "../Context/AuthContext.jsx";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useCampaign } from '../Context/CampaignContext.jsx';

const StudentDashboard = () => {
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem("activeTab") ? parseInt(localStorage.getItem("activeTab")) : 1;
  });
  const [referralInfo, setReferralInfo] = useState([]);
  const [inprogress, setInporgress] = useState([]);
  const [enrolled, setEnrolled] = useState([]);
  const [point, setPoint] = useState({});
  const { saveCampaignData, campaign, source, medium } = useCampaign();
  const [referralView, setReferralView] = useState(false);
  const [referral, setReferral] = useState(false);
  const [balance,setBalance]=useState({})


  const { token, user, setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = [
    { id: 1, label: "Home" },
    { id: 2, label: "Wallet" },
    // { id: 3, label: "Vouchers" },
    // Add more tabs as needed
  ];

  const getQueryParams = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  const campaignParam = getQueryParams("utm_campaign") || campaign || "NA";
  const sourceParam = getQueryParams("utm_source") || source || "NA";
  const mediumParam = getQueryParams("utm_medium") || medium || "NA"

  useEffect(() => {
    saveCampaignData(sourceParam, campaignParam, mediumParam);
  }, [sourceParam, campaignParam, mediumParam, saveCampaignData]);

  const studentReferral = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/referrals/my-referrals`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resp.status === 200) {
        const referrals = resp.data.referral.reverse();
        setReferralInfo(referrals);

        const progress = referrals.filter((item) => item.status === "inprogress");
        const enrolledCandidate = referrals.filter(
          (item) => item.status === "enrolled"
        );
        setInporgress(progress);
        setEnrolled(enrolledCandidate);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);
  const studentCurrentReedemPoint = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/points/balance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resp.status === 200) {
        setPoint(resp.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);
  useEffect(() => {
    studentCurrentReedemPoint();
  }, [studentCurrentReedemPoint]);

  const studentPoint = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/points/currentbalance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resp.status === 200) {
        setBalance(resp.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);
  useEffect(() => {
    studentPoint();
  }, [studentPoint]);

  useEffect(() => {
    studentReferral();
  }, [studentReferral]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    localStorage.setItem("activeTab", tabId);
    setReferralView(false);
    setReferral(false)
  };

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const signout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null)
    navigate(location.state?.from?.pathname ?? "/", {
      replace: true,
    });
    saveCampaignData('', '', '')
  };

  useEffect(() => {
    const storedPopupState = localStorage.getItem('referralView');
    if (storedPopupState === 'true') {
      setReferralView(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('referralView', referralView);
  }, [referralView]);

  useEffect(() => {
    const storedPopupState = localStorage.getItem('referral');
    if (storedPopupState === 'true') {
      setReferral(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('referralView', referral);
  }, [referral]);


  return (
    <div>
      {/* header */}
      <div>
        <div className="max-w-7xl 2xl:max-w-[90rem] m-auto bg-white shadow-xl py-4">
          <div className="flex justify-between items-center px-4">
            <div className="flex items-center gap-x-5">
              <a href="/student">
                <div>
                  <img src={logo} alt="logo" />
                </div>
              </a>
              <div className="text-sm border-l-[1px] border-black pl-2">
                {user.first_name}
              </div>
            </div>
            <div className="text-sm cursor-pointer" onClick={signout}>
              Sign Out
            </div>
          </div>
        </div>
      </div>
      {/* header */}
      {/* tabs */}
      <div className="max-w-7xl 2xl:max-w-[90rem] m-auto bg-white shadow-xl">
        <div className="bg-[#004f6b] flex justify-start gap-x-8 flex-wrap p-6 ">
          {tabs.map((tab) => (
            <div
              className={
                activeTab === tab.id
                  ? "text-black cursor-pointer w-[7rem] py-2 rounded-lg font-bold bg-white cursor-pointer "
                  : "text-white cursor-pointer w-max"
              }
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <Home
          studentReferral={studentReferral}
          activeTab={activeTab}
          referralInfo={referralInfo}
          inprogress={inprogress}
          enrolled={enrolled}
          point={point}
          balance={balance}
          referralView={referralView}
          setReferralView={setReferralView}
        />
        <Wallet activeTab={activeTab} point={point} studentReferral={studentReferral} referral={referral} setReferral={setReferral} balance={balance} />
        {/* <Vouchers activeTab={activeTab} /> */}
      </div>
      {/* tabs */}
    </div>
  );
};

export default StudentDashboard;
