import React, { useState, useEffect } from "react";
import bannerlogo from "../../assets/LMS-Banner.jpeg";
import Carousel from "./Carousel.jsx";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext.jsx";
import { useCampaign } from '../../Context/CampaignContext.jsx';



const Home = ({
  studentReferral,
  activeTab,
  referralInfo,
  inprogress,
  enrolled,
  point,
  referralView,
  setReferralView,
  balance
}) => {
  const [filter, setFilter] = useState("all");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [program, setProgram] = useState("MBA");
  const [error, setError] = useState("");
  const [validatephone, setValidatePhone] = useState("");
  const [validateemail, setValidateEmail] = useState("");
  const [success, setSuccess] = useState("");
  const { token } = useAuth();
  const [countries, setCountries] = useState([]);
  const [proxycountry, setProxycountry] = useState("India");
  const { campaign, source, medium, saveCampaignData } = useCampaign();
  const [reedemessage, setReedemessage] = useState("");
  const [disablebutton, setDisablebutton] = useState(false);
  const [currentredemptionrule, setCurrentredemptionrule] = useState(null)
 
  function formatDate(utcTimestamp) {
    // Create a Date object from the UTC timestamp
    const date = new Date(utcTimestamp);
  
    // Convert to Indian Standard Time (IST) by adding 5 hours 30 minutes
    const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);
  
    // Format the date and time as per IST
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Use 12-hour clock (AM/PM)
    };
  
    const formattedISTDate = istDate.toLocaleString('en-IN', options);
  
    return formattedISTDate;
  }

  const getFilteredReferrals = () => {
    switch (filter) {
      case "inprogress":
        return inprogress;
      case "enrolled":
        return enrolled;
      case "all":
      default:
        return referralInfo;
    }
  };

  const filteredReferrals = getFilteredReferrals();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/referrals`,
        {
          candidate_name: firstname + " " + lastname,
          candidate_email: email,
          candidate_phone: phone,
          candidate_country: proxycountry,
          candidate_program: program,
          campaign: campaign,
          utm_source: source,
          medium: medium,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 201) {
          setDisablebutton(true)
          setSuccess(resp.data.message);
          setTimeout(() => {
            setSuccess("");
            setFirstname("");
            setLastname("");
            setEmail("");
            setPhone("");
            setProxycountry("India");
            setProgram("MBA");
            setReferralView(false);
            if (studentReferral) {
              studentReferral(); // Call the function if it's passed
              setDisablebutton(false)
            }
          }, 2000);
          saveCampaignData("", "", "")
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.message)
        if (error.response.data.errors) {
          setValidatePhone(error.response.data.errors[0].isEmail)
          setValidateEmail(error.response.data.errors[0].isPhoneNumber)
        }
        setTimeout(() => {
          setError("")
          setValidatePhone("")
          setValidateEmail("")
        }, 2000)
      });
  };

  const redeemPointApi = async () => {
    if (point.balance === 0) {
      setReedemessage("Points must be greater than zero to redeem rewards.")
      setTimeout(() => {
        setReedemessage("")
      }, 2000)
    } else {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/points/redeem`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (resp.status === 200) {
          let ssoToken = resp.data.redirectUrl.ssoToken;
          window.open(`${process.env.REACT_APP_XOXODAY_STOREFRONT_SSO_REDIRECT_ENDPOINT_URL}/${ssoToken}`, '_blank');
        }
      } catch (error) {
        console.log(error);
      }
    }

  }

  const redeemptionRule = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/pointrule/currentcap`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resp.status === 200) {
        setCurrentredemptionrule(resp.data.pointRule.capvalue)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    redeemptionRule()
  }, [])


  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      let countryNames = response.data.map(country => country.name.common);
      setCountries(countryNames);
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  function cancelFunction() {
    setProxycountry('India')
    setProgram("MBA")
    setFirstname("")
    setLastname("")
    setEmail("")
    setPhone("")
    setReferralView(false)
  }

  return (
    <div>
      {activeTab === 1 && !referralView && (
        <div className="max-w-7xl m-auto">
          <div className="flex flex-col items-start lg:flex-row gap-y-6 lg:items-center px-6 py-2 gap-x-[3rem] my-4">
            <div className=" lg:border-r-[1px] pr-4">
              <div
                className="border border-red-500 rounded-full  px-8  py-2  text-red-500 cursor-pointer "
                onClick={() => setReferralView(true)}
              >
                Add new referral
              </div>
            </div>
            <div className="lg:border-r-[1px] pr-4">
              <button
                className="border border-red-500 rounded-full  px-8 py-2   text-red-500 cursor-pointer"
                onClick={redeemPointApi}
              >
                Redeem points
              </button>
            </div>
            <div>
              <div className="text-xs text-start text-[#5b6471]">
                Current balance
              </div>
              <div className="text-3xl">{balance.balance ? balance.balance : '0.00'} pts</div>
            </div>
            <div>
              <div className="text-xs text-start text-[#5b6471]">
                Today Redeemption Limit
              </div>
              <div className="text-3xl">{point.balance ? point.balance : '0.00'} pts</div>
            </div>
          </div>
          {(currentredemptionrule !== null && currentredemptionrule !== 0)  && (
            <div className="my-4">
              <div className="text-start px-8 bg-red-500 text-white py-4 md:w-max ml-6">
                <strong className="font-extrabold">Notice:</strong> You can redeem a maximum of {currentredemptionrule} points per day.
              </div>
            </div>
          )}
          <div className="text-red-500 ml-0 w-max ml-16">{reedemessage ? reedemessage : ''}</div>
          <div className="flex flex-col lg:flex-row gap-x-8 px-6">
            <div className="flex items-center justify-between px-4 w-[17rem] h-[4rem]  border border-[#e5e7eb] my-3 rounded-lg">
              <div className="text-xl">Total referrals</div>
              <div className="text-red-500 text-xl font-bold">
                {" "}
                {referralInfo.length}
              </div>
            </div>
            <div className="flex items-center justify-between px-4 w-[17rem] h-[4rem]  border border-[#e5e7eb] my-3 rounded-lg">
              <div className="text-xl">In Progress</div>
              <div className="text-red-500 text-xl font-bold">
                {inprogress.length}
              </div>
            </div>
            <div className="flex items-center justify-between px-4 w-[17rem] h-[4rem]  border border-[#e5e7eb] my-3 rounded-lg">
              <div className="text-xl">Enrolled</div>
              <div className="text-red-500 text-xl font-bold">
                {enrolled.length}
              </div>
            </div>
            <div className="w-[18rem] lg:w-[40rem]">
              <a
                href="https://web.whatsapp.com/send?text=%20Hi,%20I%20recommend%20Vignan%20Online%20for%20a%20future-proof%20career.%20Use%20this%20invitation%20from%20me%20to%20enroll%20now%20in%20an%20online%20degree%20program%20and%20elective%20of%20your%20choice.%20%20%20http://uat.vignanonline.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={bannerlogo} alt="bannerlogo" />
              </a>
            </div>
          </div>
          {/* my referal */}
          <div>
            <div className="text-red-500 text-2xl font-medium py-4 text-start px-8">
              My Referrals
            </div>
            <div className="overflow-x-auto h-[20rem] lg:h-[15rem] lg:overflow-y-auto my-8">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border">Sr. No.</th>
                    <th className="px-4 py-2 border">Reference </th>
                    <th className="px-4 py-2 border">Status</th>
                    <th className="px-4 py-2 border">
                      Filter <br />
                      <select
                        id="options"
                        name="options"
                        className=" p-2"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="inprogress">Inprogress</option>
                        <option value="enrolled">Enrolled</option>
                      </select>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReferrals.map((item, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border">{index + 1}</td>
                      <td className="px-4 py-2 border">
                        {item.candidate_name} <br />
                        {item.candidate_email} / {item.candidate_phone}
                      </td>
                      <td className="px-4 py-2 border">{item.status}</td>
                      <td className="px-4 py-2 border">
                        {formatDate(item.updated_at)}
                      </td>
                    </tr>
                  ))}
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          </div>
          {/* my referal */}
          <div>
            <div className="my-8">
              <Carousel />
            </div>
          </div>
        </div>
      )}
      {referralView && (
        <div className="max-w-7xl m-auto">
          <div className="text-2xl mt-4 mb-2">Add new referral</div>
          <div className="text-sm text-[#999ea8] font-bold">
            Enter few details of your referral and our team will get started
            with the process.
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">First name</div>
              <input
                type="text"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex:ravis"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Last name</div>
              <input
                type="text"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex:Hiremath"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Email</div>
              <input
                type="email"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex:email@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Country</div>
              <select
                id="proxyCountry"
                name="proxyCountry"
                className="bg-white w-[100%] p-2 border-[1px]"
                value={proxycountry} // Selected country
                onChange={(e) => setProxycountry(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select country
                </option>
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Phone number</div>
              <input
                type="text"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex: 4598904300"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  // Regular expression to allow only numbers and limit to 10 digits
                  if (/^\d{0,10}$/.test(value)) {
                    setPhone(value);
                  }
                }}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Program</div>
              <select
                name=""
                id=""
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                value={program}
                onChange={(e) => setProgram(e.target.value)}
                required
              >
                <option value="MBA">MBA</option>
                <option value="MCA">MCA</option>
                <option value="M.Com">M.Com</option>
                <option value="MA">MA</option>
                <option value="BBA">BBA</option>
                <option value="BCA">BCA</option>
                <option value="B.Com">B.Com</option>
              </select>
              <div className="text-xs">
                Select program you think your acquaintance might be interested
                in.
              </div>
            </div>
            {error !== "" && <div className="text-red-500">{error}</div>}
            {validatephone !== "" && <div className="text-red-500">{validatephone}</div>}
            {validateemail !== "" && <div className="text-red-500">{validateemail}</div>}
            {success !== "" && <div className="text-green-500 font-bold text-lg">{success}</div>}
            <div>
              <button
                className="bg-red-500 w-[80%] lg:w-[30%] m-auto py-2 rounded-full text-white font-bold text-lg cursor-pointer"
                type="submit"
                disabled={disablebutton}
              >
                Save & add
              </button>
              <div
                className="border border-red-500 w-[80%] lg:w-[30%] m-auto py-2 rounded-full text-red-500 font-bold text-lg my-4 cursor-pointer"
                onClick={cancelFunction}
              >
                Cancel
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Home;
