import React from "react";
import amazon from "../../assets/Artboard 4.png";
import tanishq from "../../assets/Artboard 2.png";
import myntra from "../../assets/Artboard 4.png";
import hp from "../../assets/Artboard 5.png";
import zomato from "../../assets/Artboard 6.png";
import bookymyshow from "../../assets/Artboard 7.png";
import Uber from "../../assets/Artboard 8.png";
import decathlon from "../../assets/Artboard 9.png";
import croma from "../../assets/Artboard 11.png";
import disney from "../../assets/Artboard 12.png";
import pepperfry from "../../assets/Artboard 13.png";
import cultpass from "../../assets/Artboard 14.png";
import phonepay from "../../assets/Artboard 15.png";
import nike from "../../assets/Artboard 16.png";
import ganna from "../../assets/Artboard 17.png";
import homeCenter from "../../assets/Artboard 18.png";
import bigbasket from "../../assets/Artboard 19.png";
import bodycraft from "../../assets/bodycraft.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const imageUrls = [
    amazon,
    tanishq,
    myntra,
    hp,
    zomato,
    bookymyshow,
    Uber,
    decathlon,
    croma,
    disney,
    pepperfry,
    cultpass,
    phonepay,
    nike,
    ganna,
    homeCenter,
    bigbasket,
    bodycraft,
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="text-red-500 text-2xl text-start px-3 ">
        Popular Vouchers
      </div>
      <div className="px-6">
        <Slider {...settings}>
          {imageUrls.map((image, index) => (
            <div key={index} className="">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-[10rem] shadow-md"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
