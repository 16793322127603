import React, { createContext, useContext, useState, useEffect } from "react";

const CampaignContext = createContext();

const CampaignProvider = ({ children }) => {
  // State to store campaign, utm_source, and medium, initialized from localStorage
  const [campaign, setCampaign] = useState(localStorage.getItem("utm_campaign") || null);
  const [source, setSource] = useState(localStorage.getItem("utm_source") || null);
  const [medium, setMedium] = useState(localStorage.getItem("utm_medium") || null);

  // Effect to sync campaign data with localStorage whenever they change
  useEffect(() => {
    const updateLocalStorage = (key, value) => {
      if (value) {
        localStorage.setItem(key, value);
      } else {
        localStorage.removeItem(key);
      }
    };
  
    // Update localStorage for all values in a single effect
    updateLocalStorage("utm_campaign", campaign);
    updateLocalStorage("utm_source", source);
    updateLocalStorage("utm_medium", medium);
  }, [campaign, source, medium]);
  

  // Helper function to set campaign data and replace the previous one
  const saveCampaignData = (newUtmSource, newCampaign, newMedium) => {
      // Only update state if values are valid (not empty or "NA")
      if (newUtmSource && newUtmSource !== "NA") setSource(newUtmSource.trim());
      if (newCampaign && newCampaign !== "NA") setCampaign(newCampaign.trim());
      if (newMedium && newMedium !== "NA") setMedium(newMedium.trim());
  };

  // Provide the context value to children
  return (
    <CampaignContext.Provider value={{ campaign, source, medium, saveCampaignData }}>
      {children}
    </CampaignContext.Provider>
  );
};

const useCampaign = () => useContext(CampaignContext);

export { CampaignProvider, useCampaign };


