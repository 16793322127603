import React, { useState, useEffect, useCallback } from "react";
import logo from "../assets/vignan_online.png";
import Analytics from "../Component/sales/Analytics.jsx";
import AllReferall from "../Component/sales/AllReferall.jsx";
// import InProgress from "../Component/sales/InProgress.jsx";
// import Unknown from "../Component/sales/Unknown.jsx";
// import Enrolled from "../Component/sales/Enrolled.jsx";
// import Rejected from "../Component/sales/Rejected.jsx";
import Myteam from "../Component/sales/Myteam.jsx";
// import Campaign from "../Component/sales/Campaign.jsx";
import Add from "../Component/sales/Add.jsx";
 import DailyRedeemption from "../Component/sales/DailyRedeemption.jsx";
import AwardRule from "../Component/sales/AwardRule.jsx"
import { useAuth } from "../Context/AuthContext.jsx";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useCampaign } from '../Context/CampaignContext.jsx';

const SalesDashboard = () => {
  const { token, user,setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [toggletab, setToggletab] = useState(false);
  const [dashboardata, setDashboardata] = useState({});
  const { saveCampaignData, campaign, source, medium } = useCampaign();

  const getQueryParams = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };
  
  const campaignParam = getQueryParams("utm_campaign") || campaign || "NA";
  const sourceParam = getQueryParams("utm_source") || source || "NA";
  const mediumParam = getQueryParams("utm_medium") || medium || "NA"

  useEffect(() => {
    saveCampaignData(sourceParam, campaignParam, mediumParam);
  }, [sourceParam, campaignParam, mediumParam, saveCampaignData]);

  const AllReferallInfo = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/referrals/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resp.status === 200) {
        console.log("updated")
      }
    } catch (error) {
      console.log("Error fetching referrals:", error);
    }
  }, [token] );

  useEffect(() => {
    AllReferallInfo();
  }, [AllReferallInfo]);



  const dashboardInfo = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/referrals/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resp.status === 200) {
        setDashboardata(resp.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    dashboardInfo();
  }, [dashboardInfo]);

  const signout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null)
    navigate(location.state?.from?.pathname ?? "/", {
      replace: true,
    });
  };

  const tabs = [
    { id: 1, label: "Analytics",  },
    { id: 2, label: "Referrals", },
    // { id: 3, label: "In progress",  },
    // { id: 4, label: "Unknown",  },
    // { id: 5, label: "Enrolled", },
    // { id: 6, label: "Rejected",  },
    { id: 7, label: "My team" },
    // { id: 8, label: "Campaigns" },
    { id: 9, label: "Add", },
    { id: 11, label: "Awards Rule", },
      { id: 10, label: "Daily Redeemption Rule", },
    // Add more tabs as needed
  ];
  const filteredTabs = tabs.filter(tab => {
    // Show "My team" for Admin and SalesHead
    if (tab.label === "My team") {
      return user.user_type === 'Admin' || user.user_type === 'SalesHead';
    }
     
    if (tab.label === "Daily Redeemption Rule") {
      return user.user_type === 'Admin';
    }

    if (tab.label === "Analytics") {
      return user.user_type === 'Admin' || user.user_type === 'SalesHead';
    }
  
    // Show "Awards Rule" only for Admin
    if (tab.label === "Awards Rule") {
      return user.user_type === 'Admin';
    }
  
    // Hide "Add" for DepartmentHead
    if (tab.label === "Add" && user.user_type === 'DepartmentHead') {
      return false;
    }
  
    // Otherwise, show the tab for all other users
    return true;
  });
  
  useEffect(() => {
    // Set initial active tab based on filtered tabs and user type
    if (filteredTabs.length > 0) {
      setActiveTab(filteredTabs[0].id); // Set to the first accessible tab
    }
  }, []);


  const handleTabClick = (tabLabel) => {
    setActiveTab(tabLabel);
    setToggletab(false)
  };
  return (
    <>
      {/* header */}
      <div>
        <div className="max-w-7xl 2xl:max-w-[90rem] m-auto bg-white shadow-xl py-4">
          <div className="flex justify-between items-center px-4">
            <div className="flex items-center gap-x-5">
              <a href="/sales">
              <div>
                <img src={logo} alt="logo" />
              </div>
              </a>
              <div className="text-sm border-l-[1px] border-black pl-2">
                {user.first_name}
              </div>
            </div>
            <div className="text-sm cursor-pointer" onClick={signout}>
              Sign Out
            </div>
          </div>
        </div>
      </div>
      {/* header */}
      {/* tabs */}
      <div className="max-w-7xl 2xl:max-w-[90rem] m-auto bg-white shadow-xl">
        <div className="bg-[#004f6b] flex justify-start gap-x-8 flex-wrap p-6 ">
          {filteredTabs.map((tab) => (
            <div
              className={
                activeTab === tab.id
                  ? "text-black cursor-pointer w-max"
                  : "text-white cursor-pointer w-max"
              }
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>

        <Analytics activeTab={activeTab} dashboardata={dashboardata} />
        <AllReferall activeTab={activeTab} />
        {/* <InProgress activeTab={activeTab} /> */}
        {/* <Unknown activeTab={activeTab} /> */}
        {/* <Enrolled activeTab={activeTab} /> */}
        {/* <Rejected activeTab={activeTab} /> */}
        <Myteam activeTab={activeTab} toggletab={toggletab} setToggletab={setToggletab}/>
        {/* <Campaign activeTab={activeTab} /> */}
        <Add activeTab={activeTab} setActiveTab={setActiveTab} AllReferallInfo={AllReferallInfo} />
       <AwardRule activeTab={activeTab} />
       <DailyRedeemption activeTab={activeTab} />
      </div>
      {/* tabs */}
    </>
  );
};

export default SalesDashboard;
