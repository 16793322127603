import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../Context/AuthContext.jsx";



const AwardRule = ({ activeTab }) => {
    const { token,user } = useAuth();
    const [selectedValue, setSelectedValue] = useState('none');
    const [points, setPoints] = useState(null);
    const [description, setDescription] = useState('')
    const [descriptonsecond, setDescriptionSecond] = useState('')
    const [message, setMessage] = useState("");
    const [fields, setFields] = useState([{ lower_limit: '', upper_limit: '', points: '' }]);
    const [timeoutId, setTimeoutId] = useState(null);
    const [messageTwo, setMessageTwo] = useState("");
    const [runningAwards, setRunningAwards] = useState([])
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    const validateSlabFields = () => {
        return fields.every(field => {
            const lower = parseFloat(field.lower_limit);
            const upper = parseFloat(field.upper_limit);
            const points = parseFloat(field.points);
            return lower !== '' && upper !== '' && points !== '' && upper > lower;
        });
    };

    const addFields = () => {
        const newFields = [...fields, { lower_limit: '', upper_limit: '', points: '' }];
        const lastUpperLimit = fields[fields.length - 1].upper_limit; // Get the last upper limit

        // Set the new lower limit to be greater than the last upper limit if it exists
        if (lastUpperLimit) {
            newFields[newFields.length - 1].lower_limit = parseFloat(lastUpperLimit) + 1;
        }

        setFields(newFields);
    };
    const removeLastField = () => {
        // Only remove the last field if there's more than one
        if (fields.length > 1) {
            const newFields = fields.slice(0, -1); // Remove the last field
            setFields(newFields);
        } else {
            alert("You must have at least one slab.");
        }
    };
    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newFields = [...fields];

        // Update the field value
        newFields[index][name] = value;

        // Clear the previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timeout for validation
        const id = setTimeout(() => {
            validateFields(newFields, index, name, value);
            setIsSubmitDisabled(!validateSlabFields());
        }, 2000); // Adjust the debounce time as needed (in milliseconds)

        setTimeoutId(id);
        setFields(newFields);
    };

    // Validation logic
    const validateFields = (newFields, index, name, value) => {
        // Validate upper_limit is greater than lower_limit for this specific field
        if (name === 'upper_limit') {
            const lowerLimitValue = parseFloat(newFields[index].lower_limit);
            if (value && parseFloat(value) <= lowerLimitValue) {
                alert('Upper limit must be greater than lower limit for this field');
            }
        }

        // Validate that new lower_limit is greater than the previous upper_limit
        if (name === 'lower_limit' && index > 0) {
            const previousUpperLimit = parseFloat(newFields[index - 1].upper_limit);
            if (value && parseFloat(value) <= previousUpperLimit) {
                alert(`Lower limit must be greater than previous upper limit (${previousUpperLimit})`);
            }
        }

        // Validate all fields: upper_limit must be greater than lower_limit for all fields
        const invalidFields = newFields.some((field) => {
            const lower = parseFloat(field.lower_limit);
            const upper = parseFloat(field.upper_limit);
            return upper <= lower;
        });

        if (invalidFields) {
            alert('All upper limits must be greater than their corresponding lower limits.');
        }
    };

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/api/awards/update-award`,
                {
                    "rule_type": "flat_rate",
                    "points": points,
                    "description": description,
                    "slab_details": []
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            if (response.status === 200) {
                setMessage(response.data.message)
            }
            setTimeout(() => {
                setMessage("");
                setDescription("")
                setPoints("")
                currentRules()
            }, 2000)

        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmitSlab = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/api/awards/update-award`,
                {
                    "rule_type": "slab_wise",
                    "points": 0,
                    "description": descriptonsecond,
                    "slab_details": fields
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            if (response.status === 200) {
                setMessageTwo(response.data.message)
            }
            setTimeout(() => {
                setMessageTwo("");
                setDescriptionSecond("")
                setFields([{ lower_limit: '', upper_limit: '', points: '' }]);
                currentRules()
            }, 2000)

        } catch (error) {
            console.error(error);
        }
    }

    const currentRules = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/awards/current-rule`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                setRunningAwards(response.data.awardRule);
            }
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    useEffect(() => {
        currentRules();
    }, [currentRules]);
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };
    useEffect(() => {
        setIsSubmitDisabled(!validateSlabFields()); // Check if submit should be disabled on mount
    }, [fields]);

    const lastEntry = runningAwards?.last_updated_by?.slice(-1)[0];
    const remainingEntries = runningAwards?.last_updated_by?.slice(0, -1);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <>
            { activeTab === 11 && runningAwards && <div>
                <div>
                    <div className="text-red-500 text-2xl font-medium py-4 text-start px-4">
                        Current Rules
                    </div>
                    <div className="overflow-x-auto  overflow-y-auto my-8">
                        <table className="min-w-full bg-white border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border">Sr. No.</th>
                                    <th className="px-4 py-2 border">Last Updated By</th>
                                    <th className="px-4 py-2 border">Rule</th>
                                    <th className="px-4 py-2 border">Points</th>
                                    <th className="px-4 py-2 border">Slab Detail</th>
                                    <th className="px-4 py-2 border">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Show the last entry */}
                                {lastEntry ? (
                                    <tr>
                                        <td className="px-4 py-2 border">1</td>
                                        <td className="px-4 py-2 border">
                                            {lastEntry.email}
                                            <br />
                                            {formatDate(lastEntry.timestamp)}
                                        </td>
                                        <td className="px-4 py-2 border">{lastEntry.pointRule === 'flat_rate' ? "Flat Rate" : "Slab Wise"}</td>
                                        <td className="px-4 py-2 border">{lastEntry.pointsflat || 'NA'}</td>
                                        <td className="px-4 py-2 border">
                                            {lastEntry?.pointSlab?.length > 0 ? (
                                                <ul className="list-disc ml-5">
                                                    {lastEntry.pointSlab.map((slab, i) => (
                                                        <li key={i}>
                                                            Points: {slab.points}, Lower Limit: {slab.lower_limit}, Upper Limit: {slab.upper_limit}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                'NA'
                                            )}
                                        </td>
                                        <td className="px-4 py-2 border">{lastEntry?.description}</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="px-4 py-2 border text-center">
                                            No updates available
                                        </td>
                                    </tr>
                                )}

                                {/* Expandable section for the remaining entries */}
                                {remainingEntries?.length > 0 && (
                                    <>
                                        <tr>
                                            <td colSpan="6" className="px-4 py-2 border text-center">
                                                <button
                                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                                    onClick={toggleExpand}
                                                >
                                                    {isExpanded ? 'Hide Details' : 'Show More'}
                                                </button>
                                            </td>
                                        </tr>
                                        {isExpanded &&
                                            remainingEntries.reverse().map((entry, index) => (
                                                <tr key={index}>
                                                    <td className="px-4 py-2 border">{index + 2}</td>
                                                    <td className="px-4 py-2 border">
                                                        {entry.email}
                                                        <br />
                                                        {formatDate(entry.timestamp)}
                                                    </td>
                                                    <td className="px-4 py-2 border">{entry.pointRule}</td>
                                                    <td className="px-4 py-2 border">{entry.pointsflat || 'NA'}</td>
                                                    <td className="px-4 py-2 border">
                                                        {entry?.pointSlab?.length > 0 ? (
                                                            <ul className="list-disc ml-5">
                                                                {entry.pointSlab.map((slab, i) => (
                                                                    <li key={i}>
                                                                        Points: {slab.points}, Lower Limit: {slab.lower_limit}, Upper Limit: {slab.upper_limit}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            'NA'
                                                        )}
                                                    </td>
                                                    <td className="px-4 py-2 border">{entry?.description}</td>
                                                </tr>
                                            ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}
            {
                activeTab === 11 && (
                    <div className="text-start pl-8">
                        <h1 className="pt-2 py-8 text-2xl font-medium">Update Award Rules</h1>
                        <div className="">
                            <label className="mr-4">Choose an Award Rule Type:</label>
                            <select id="mySelect" name="options" className="my-4 w-[10rem] text-center py-2 outline-none" value={selectedValue} onChange={handleSelectChange}>
                                <option value="none" disabled>Select an option</option>
                                <option value="flat_rate">Flat Rate</option>
                                <option value="slab_wise">Slab Wise</option>
                            </select>
                        </div>
                        {selectedValue === 'flat_rate' && <div>
                            <form action="" className="py-3" onSubmit={handleSubmit} >
                                <div className="font-semibold">Description:</div>
                                <input type="text" placeholder="Enter Descriptions" className="border-[1px] border-black py-2 rounded outline-none pl-2 w-[20rem]" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                <div className="font-semibold">Points:</div>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Enter Points"
                                    required
                                    className="border-[1px] border-black py-2 rounded outline-none pl-2"
                                    value={points}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) > 0)) {
                                            setPoints(value);
                                        }
                                    }}
                                    min="1"
                                    step="1"
                                />


                                <button type="submit" className="bg-red-500 py-3 px-10 rounded ml-6">Add</button>
                            </form>
                        </div>}
                        {message && <div>{message}</div>}
                        {selectedValue === 'slab_wise' && <div>
                            <div className="font-semibold">Description:</div>
                            <input type="text" placeholder="Enter Descriptions" required className="border-[1px] border-black py-2 rounded outline-none pl-2 w-[20rem]" value={descriptonsecond} onChange={(e) => setDescriptionSecond(e.target.value)} />
                            <div className="font-semibold py-2">slab rule:</div>
                            <form onSubmit={handleSubmitSlab}>
                                {fields.map((field, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <input
                                            type="text"
                                            name="lower_limit"
                                            placeholder="Lower Limit"
                                            value={field.lower_limit}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    handleChange(index, e);
                                                }
                                            }}
                                            className="border-[1px] border-black py-2 pl-2"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="upper_limit"
                                            placeholder="Upper Limit"
                                            value={field.upper_limit}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    handleChange(index, e);
                                                }
                                            }}
                                            className="border-[1px] border-black ml-2 py-2 pl-2"
                                            required
                                        />
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            name="points"
                                            placeholder="Points"
                                            value={field.points}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^[1-9]\d*$/.test(value) || value === "") {
                                                    handleChange(index, e);
                                                }
                                            }}
                                            className="border-[1px] border-black ml-2 py-2 pl-2"
                                            required
                                        />

                                    </div>
                                ))}
                                <div className="flex gap-5">
                                    <div type="button" onClick={addFields} className="bg-red-500 w-max py-3 px-4 rounded cursor-pointer">
                                        Add More Fields
                                    </div>
                                    {fields.length > 1 && (
                                        <div
                                            type="button"
                                            onClick={removeLastField}
                                            className="bg-red-500 ml-2 px-4 py-3 text-black rounded cursor-pointer"
                                        >
                                            Remove Last Field
                                        </div>
                                    )}
                                </div>
                                <button type="submit" className="bg-red-500 my-4 px-12 py-3 rounded cursor-pointer" disabled={isSubmitDisabled} >Submit</button>
                            </form>
                            <div>
                                {messageTwo ? messageTwo : ""}
                            </div>
                        </div>}
                    </div>
                )
            }
        </>
    )
}


export default AwardRule