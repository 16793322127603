import React, { useState, useEffect, useCallback } from "react";
import Carousel from "./Carousel";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext.jsx";
import { useCampaign } from '../../Context/CampaignContext.jsx';


const Wallet = ({ activeTab, point, studentReferral, referral, setReferral,balance }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [program, setProgram] = useState("MBA");
  const [success, setSuccess] = useState("");
  const [countries, setCountries] = useState([]);
  const [proxycountry, setProxycountry] = useState("India");
  const [transaction, setTransaction] = useState([]);
  const [lastTransaction, setLastTransaction] = useState("");
  const [error, setError] = useState('')
  const [validatephone, setValidatePhone] = useState("");
  const [validatemail, setValidateEmail] = useState("");
  const { token } = useAuth();
  const { campaign, source, medium, saveCampaignData } = useCampaign();
  const [reedemessage, setReedemessage] = useState("");
  const [disabledbutton, setDisablebutton] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/referrals`,
        {
          candidate_name: firstname + " " + lastname,
          candidate_email: email,
          candidate_phone: phone,
          candidate_country: proxycountry,
          candidate_program: program,
          campaign: campaign,
          utm_source: source,
          medium: medium,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 201) {
          setDisablebutton(true)
          setSuccess(resp.data.message);
          setTimeout(() => {
            setSuccess("")
            setFirstname("");
            setLastname("");
            setEmail("");
            setPhone("");
            setProxycountry("India");
            setProgram("MBA");
            setReferral(false);
            if (studentReferral) {
              studentReferral();
              setDisablebutton(false) // Call the function if it's passed
            }
            saveCampaignData("", "", "")
          }, 2000);

        }
      })
      .catch((error) => {
        setError(error.response.data.message)
        if (error.response.data.errors) {
          setValidatePhone(error.response.data.errors[0].isEmail)
          setValidateEmail(error.response.data.errors[0].isPhoneNumber)
        }
        setTimeout(() => {
          setError("")
          setValidatePhone("")
          setValidateEmail("")
        }, 2000)
      });
  };

  const redeemPointApi = async () => {
    if (point.balance > 0) {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/points/redeem`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (resp.status === 200) {
          let ssoToken = resp.data.redirectUrl.ssoToken;
          window.open(`${process.env.REACT_APP_XOXODAY_STOREFRONT_SSO_REDIRECT_ENDPOINT_URL}/${ssoToken}`, '_blank');     
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setReedemessage("Points must be greater than zero to redeem rewards.")
      setTimeout(() => {
        setReedemessage("")
      }, 2000)
    }

  }

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      let countryNames = response.data.map(country => country.name.common);
      setCountries(countryNames);
    } catch (err) {
      console.log(err)
    }
  };

  const fetchAllTransaction = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/points/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setTransaction(response.data.data.transactions);
        setLastTransaction(response.data.data.transactions[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    fetchAllTransaction();
  }, [fetchAllTransaction]);


  useEffect(() => {
    fetchCountries();
  }, []);

  function cancelFuntion() {
    setProxycountry('India')
    setProgram("MBA")
    setFirstname("")
    setLastname("")
    setEmail("")
    setPhone("")
    setReferral(false)
  }

  function formatDate(utcTimestamp) {
    // Create a Date object from the UTC timestamp
    const date = new Date(utcTimestamp);
  
    // Convert to Indian Standard Time (IST) by adding 5 hours 30 minutes
    const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);
  
    // Format the date and time as per IST
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Use 12-hour clock (AM/PM)
    };
  
    const formattedISTDate = istDate.toLocaleString('en-IN', options);
  
    return formattedISTDate;
  }


  return (
    <div>
      {activeTab === 2 && !referral && (
        <div>
          <div className="max-w-7xl m-auto">
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
              <div className="flex items-center px-6 py-2 gap-x-[1.5rem] my-4">
                <div className="border-r-[1px] pr-4">
                  <div
                    className="border border-red-500 rounded-full px-2  lg:px-8  py-2  text-red-500 cursor-pointer text-sm  lg:text-lg xl:text-md"
                    onClick={() => setReferral(true)}
                  >
                    Add new referral
                  </div>
                </div>
                <div className=" pr-4">
                  <button className="border border-red-500 rounded-full px-2 lg:px-8 py-2 text-red-500 cursor-pointer text-sm lg:text-lg xl:text-md" onClick={redeemPointApi}>
                    Redeem points
                  </button>
                </div>
              </div>

              <div className="flex flex-wrap gap-x-4 px-6 gap-y-4">
              <div className="flex justify-between items-center lg:w-[15rem] xl:w-[20rem] h-[3rem] border px-3">
                  <div className="font-medium text-[#374151] text-sm lg:text-lg xl:text-md">
                    Current balance{" "}
                  </div>
                  <div className="text-red-500 font-medium text-sm lg:text-lg xl:text-md">
                    {balance.balance ? balance.balance : '0.00'} pts
                  </div>
                </div>
                <div className="flex justify-between items-center lg:w-[15rem] xl:w-[20rem] h-[3rem] border px-3">
                  <div className="font-medium text-[#374151] text-sm lg:text-lg xl:text-md">
                  Today Redeemption Limit{" "}
                  </div>
                  <div className="text-red-500 font-medium text-sm lg:text-lg xl:text-md">
                    {point.balance ? point.balance : '0.00'} pts
                  </div>
                </div>
                <div className="flex justify-between items-center xl:w-[20rem] h-[3rem] border px-3">
                  <button
                    className="font-medium text-[#374151] mr-4 text-sm lg:text-lg xl:text-md"

                  >
                    Redeemed{" "}
                  </button>
                  <div className="text-red-500 font-medium text-sm lg:text-lg xl:text-md">{lastTransaction?.total_points_redeemed ? lastTransaction.total_points_redeemed : '0.00'} pts</div>
                </div>
              </div>
            </div>
            <div className="text-red-500 ml-0 w-max ml-16">{reedemessage ? reedemessage : ''}</div>
          </div>
          <div className="border-t border-b">
            <div>
              <div className="text-red-500 text-2xl font-medium py-4 text-start px-8">
                Wallet transaction
              </div>
              <div className="overflow-x-auto h-[15rem] overflow-y-auto my-8">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">Sr. No.</th>
                      <th className="px-4 py-2 border">Transaction Id </th>
                      <th className="px-4 py-2 border">Type</th>
                      <th className="px-4 py-2 border">Points</th>
                      <th className="px-4 py-2 border">Added on</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.map((item, index) => (
                      <tr key={index}>
                        <td className="px-4 py-2 border">{index + 1}</td>
                        <td className="px-4 py-2 border">
                          {item.id}
                        </td>
                        <td className="px-4 py-2 border">{item.transaction_type}</td>
                        <td className="px-4 py-2 border">{item.points}</td>
                        <td className="px-4 py-2 border">
                          {formatDate(item.updated_at)}
                        </td>
                      </tr>
                    ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
            {/* {transaction && <div className="my-12">  
               <div className="flex justify-around">

               </div>
              </div>}
             {!transaction && <div className="py-4">No transaction yet</div> } */}
          </div>
          <div className="py-6">
            <Carousel />
          </div>
        </div>
      )}
      {referral && (
        <div className="max-w-7xl m-auto">
          <div className="text-2xl mt-4 mb-2">Add new referral</div>
          <div className="text-sm text-[#999ea8] font-bold">
            Enter few details of your referral and our team will get started
            with the process.
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">First name</div>
              <input
                type="text"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex:ravis"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Last name</div>
              <input
                type="text"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex:Hiremath"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Email</div>
              <input
                type="email"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex:email@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Country</div>
              <select
                id="proxyCountry"
                name="proxyCountry"
                className="bg-white w-[100%] p-2 border-[1px]"
                value={proxycountry}
                onChange={(e) => setProxycountry(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select country
                </option>
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Phone number</div>
              <input
                type="text"
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                placeholder="Ex: 4598904300"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setPhone(value);
                  }
                }}
                required
              />
            </div>
            <div className="w-[80%] lg:w-[30%] m-auto my-4">
              <div className="text-start">Program</div>
              <select
                name=""
                id=""
                className="border w-[100%] p-2 ml-0 rounded-lg border-[#ced4da]"
                value={program}
                onChange={(e) => setProgram(e.target.value)}
                required
              >
                <option value="MBA">MBA</option>
                <option value="MCA">MCA</option>
                <option value="M.Com">M.Com</option>
                <option value="MA">MA</option>
                <option value="BBA">BBA</option>
                <option value="BCA">BCA</option>
                <option value="B.Com">B.Com</option>
              </select>
              <div className="text-xs">
                Select program you think your acquaintance might be interested
                in.
              </div>
            </div>
            {success !== "" && <div className="text-green-500 font-bold text-lg">{success}</div>}
            {error !== "" && <div className="text-red-500">{error}</div>}
            {validatephone !== "" && <div className="text-red-500">{validatephone}</div>}
            {validatemail !== "" && <div className="text-red-500">{validatemail}</div>}
            <div>
              <button
                className="bg-red-500 w-[80%] lg:w-[30%] m-auto py-2 rounded-full text-white font-bold text-lg cursor-pointer"
                type="submit"
                disabled={disabledbutton}
              >
                Save & add
              </button>
              <div
                className="border border-red-500 w-[80%] lg:w-[30%] m-auto py-2 rounded-full text-red-500 font-bold text-lg my-4 cursor-pointer"
                onClick={cancelFuntion}
              >
                Cancel
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Wallet;
