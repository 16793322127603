
const UserInfo = () => {
  let lorem = "lorem"
    return (
        <>
       <div>{lorem}</div> 
        </>
    )
}


export default UserInfo